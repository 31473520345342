
import React, {Component} from 'react';
import {checkPermission} from "../../utils/methods";
import {Layout, NavigationBarV2, AboutPageV2} from "../../components";
import PropTypes from "prop-types";

class About extends Component {
  render() {
    const { history } = this.props;
    const authToken = (checkPermission() && localStorage && localStorage.getItem('AUTH_TOKEN')) ? true : false;
    return (
      <Layout>
        <NavigationBarV2
          history={history}
          showInternalLinks={false}
          isUser={authToken}
        />
        <AboutPageV2  history={history}/>
      </Layout>
    );
  }
}

About.propTypes = {
  history: PropTypes.object
}

export default About;