import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { /*AdminCompletedChallengeDetails,*/ CompanyChallenges, ChallengeDetailsV2} from '../../components';
import ChallengesCategory from '../../components/ChallengeCreationAdmin';
import CreateChallengeFirst from '../../components/ChallengeCreationAdmin/challengeCreate/createChallengeFirst';
import CreateChallengeSecond from '../../components/ChallengeCreationAdmin/challengeCreate/createChallengeTwo';
import CreateChallengeThree from '../../components/ChallengeCreationAdmin/challengeCreate/createChallengeThree';
import CreateChallengeFour from '../../components/ChallengeCreationAdmin/challengeCreate/createChallengeFour';
import LaunchChallenge from '../../components/ChallengeCreationAdmin/launchChallenge/launchChallengeMain';
import CreateActivityChallenge from '../../components/ChallengeCreationAdmin/ActivityChallengeCreation/index';
import LaunchActivityChallenge from '../../components/ChallengeCreationAdmin/ActivityChallengeLaunch/index';
import ChallengesChallengeLibrary from '../../components/ChallengeCreationAdmin/ActivityChallengeByTypes';
import BehaviorChallengesTypes from '../../components/ChallengeCreationAdmin/behaviorChallengeByTypes';
import EditActivityChallenge from '../../components/ChallengeCreationAdmin/ActivityChallengeLibraryEdit/index';
import BehaviorChallenge from '../../components/BehaviorChallenge/behaviorChallengeAdmin';
import BehaviorChallengeLaunch from '../../components/BehaviorChallenge/index';
import {createBehaviorLibrary, createChallenge, getBehvaiorChallengeByIdAction} from '../../redux/actions';
import { getPermissionStatus } from '../../utils/methods';
import { connect } from 'react-redux';
import { AdminRoute} from '../../components/index';
import EditChallenge from '../../components/ChallengeCreationAdmin/ActivityChallengeEdit';
class CompleteAdminChallenge extends Component {

  CompanyChallenge = () => {
    const { history, yearList, selectedYearInfo, companyInfo } = this.props;
    return (<CompanyChallenges companyInfo={companyInfo} history={history} yearList={yearList} selectedYearInfo={selectedYearInfo} selectedTabId={1}/>)
  }

  CompanyChallenge2 = () => {
    const { history, yearList, selectedYearInfo, companyInfo } = this.props;
    return (<CompanyChallenges companyInfo={companyInfo} history={history} yearList={yearList} selectedYearInfo={selectedYearInfo} defaultActiveKey={2} selectedTabId={2}/>)
  }

  showChallengeCategories = () =>{
    const { history, role } = this.props;
    return (<ChallengesCategory history={history} role={role}/>)
  }

  createChallengeStepOne = () =>{
    const { history } = this.props;
    return (<CreateChallengeFirst history={history}/>)
  }

  createChallengeStepTwo = () =>{
    const { history } = this.props;
    return (<CreateChallengeSecond history={history}/>)
  }

  createChallengeStepThree = () =>{
    const { history } = this.props;
    return (<CreateChallengeThree history={history}/>)
  }

  createChallengeStepFour = () =>{
    const { history } = this.props;
    return (<CreateChallengeFour history={history}/>)
  }

  launchChallenge = () =>{
    const { history } = this.props;
    return (<LaunchChallenge history={history}/>)
  }

  createActivityChallenge = () =>{
    const { history, role } = this.props;
    return (<CreateActivityChallenge history={history} createLibrary={true} role={role}/>)
  }

  createBehaviorChallenge = () =>{
    const { role, userPermissions, createBehaviorLibrary } = this.props;
    return (<BehaviorChallenge role={role} createLibrary={true} editLibrary={false} createBehaviorLibrary={createBehaviorLibrary} allowedRoles={getPermissionStatus("Create behavior library", userPermissions)} parentRoute="/company/challenges"/>)
  }

  launchActivityChallenge = () =>{
    const { history, role } = this.props;
    return (<LaunchActivityChallenge history={history} role={role}/>)
  }

  challengesByChallengeTypes = () =>{
    const { history, role } = this.props;
    return (<ChallengesChallengeLibrary history={history} role={role}/>)
  }

  behaviorByChallengeTypes = () =>{
    const { history, role } = this.props;
    return (<BehaviorChallengesTypes history={history} role={role}/>)
  }

  editActivityChallenge = () =>{
    const { history, role } = this.props;
    return (<EditActivityChallenge history={history} role={role}/>)
  }

  launchBehaviorChallenge = () =>{
    const { behaviorChallengeDetailsById, role, userPermissions, getBehvaiorChallengeByIdAction } = this.props;
    return (<BehaviorChallengeLaunch   role={role} createChallengeById={true} createChallenge={createChallenge} getBehvaiorChallengeByIdAction={getBehvaiorChallengeByIdAction} behaviorChallengeDetailsById={behaviorChallengeDetailsById} component={BehaviorChallenge} parentRoute="/company/challenges" editLibrary={false}  allowedRoles={getPermissionStatus("Launch behavior library", userPermissions)}/>)
  }

  editBehaviorChallenge = () =>{
    const { behaviorChallengeDetailsById, role, userPermissions, getBehvaiorChallengeByIdAction } = this.props;
    return (<BehaviorChallengeLaunch role={role} editLibrary={true} getBehvaiorChallengeByIdAction={getBehvaiorChallengeByIdAction} behaviorChallengeDetailsById={behaviorChallengeDetailsById} allowedRoles={getPermissionStatus("Update behavior library", userPermissions)} parentRoute="/company/challenges"/>)
  }

  showChalleneDetails = () =>{
    const { history, role } = this.props;
    return (<ChallengeDetailsV2 history={history} role={role} isAdminChallenge="1" parentRoute="/company/challenges"/>)
  }

  editChallenge = () =>{
    const { history, role } = this.props;
    return (<EditChallenge history={history} role={role}/>)
  }

  render() {
    const { behaviorChallengeDetailsById, role, userPermissions, getBehvaiorChallengeByIdAction, createChallenge } = this.props;
    return (
      <Switch>
        <Route path="/company/challenges/completed" render={this.CompanyChallenge2} exact={true}/>
        <Route path="/company/challenges" render={this.CompanyChallenge} exact={true}/>
        <Route path="/company/challenges/completed/:id" component={ChallengeDetailsV2} />
        <Route path="/company/challenges/categories" render={this.showChallengeCategories} exact={true}/>
        <Route path="/company/challenges/create/step1" render={this.createChallengeStepOne} exact={true}/>
        <Route path="/company/challenges/create/step2" render={this.createChallengeStepTwo} exact={true}/>
        <Route path="/company/challenges/create/step3" render={this.createChallengeStepThree} exact={true}/>
        <Route path="/company/challenges/create/step4" render={this.createChallengeStepFour} exact={true}/>
        <Route path="/company/challenges/launch" render={this.launchChallenge} exact={true}/>
        <Route path="/company/challenges/create-activty" render={this.createActivityChallenge} exact={true}/>
        <Route path="/company/challenges/activity-launch/:id" render={this.launchActivityChallenge} exact={true}/>
        <Route path="/company/challenges/categories/:type" render={this.challengesByChallengeTypes} exact={true}/>
        <Route path="/company/challenges/behavior-categories/:type" render={this.behaviorByChallengeTypes} exact={true}/>
        <Route path="/company/challenges/activity-edit/:id" render={this.editActivityChallenge} exact={true}/>
        <Route path="/company/challenges/create-behavior" render={this.createBehaviorChallenge} exact={true}/>
        <AdminRoute
          path="/company/challenges/create-behavior-challenge/:id"
          role={role}
          createChallengeById={true}
          createChallenge={createChallenge} 
          getBehvaiorChallengeByIdAction={getBehvaiorChallengeByIdAction} 
          behaviorChallengeDetailsById={behaviorChallengeDetailsById} 
          component={BehaviorChallengeLaunch} 
          parentRoute="/company/challenges" 
          editLibrary={false}  
          allowedRoles={getPermissionStatus("Launch behavior library", userPermissions)}
        />
        <AdminRoute
          path="/company/challenges/edit-behavior/:id"
          role={role}
          component={BehaviorChallengeLaunch}
          editLibrary={true}
          getBehvaiorChallengeByIdAction={getBehvaiorChallengeByIdAction}
          behaviorChallengeDetailsById={behaviorChallengeDetailsById}
          allowedRoles={getPermissionStatus("Update behavior library", userPermissions)}
          parentRoute="/company/challenges"
        />
        <Route path="/company/challenges/edit/:id" render={this.editChallenge} exact={true}/>
        <Route path="/company/challenges/:id" render={this.showChalleneDetails}/>
      </Switch>
    )
  }
}

CompleteAdminChallenge.propTypes = {
  companies: PropTypes.array,
  companyInfo: PropTypes.object,
  setCompany: PropTypes.func,
  role: PropTypes.string,
  history: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  userPermissions: PropTypes.array,
  behaviorChallengeDetailsById: PropTypes.object,
  createChallenge: PropTypes.func,
  createBehaviorLibrary: PropTypes.func,
  getBehvaiorChallengeByIdAction: PropTypes.func
}

const mapStateToProps = (state) => ({
  behaviorChallengeDetailsById: state.challenges.behaviorChallengeDetaillsById
});

const mapDispatchToProps = (dispatch) => ({
  createChallenge: (data, onHide, path) => dispatch(createChallenge(data, onHide, path)),
  createBehaviorLibrary: (data, onHide,path) => dispatch(createBehaviorLibrary(data, onHide, path)),
  getBehvaiorChallengeByIdAction: (id) => dispatch(getBehvaiorChallengeByIdAction(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteAdminChallenge);
