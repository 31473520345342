import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getPermissionStatus } from '../../utils/methods';
import { connect } from 'react-redux';
import NewAdminSurveys from '../../components/AdminSurveys/NewAdminSurveys';
// import SurveyInsights from '../../components/AdminSurveys/SurveyInsights';

const AdminSurveys = ({ history, yearList, selectedYearInfo, companyInfo, role, userPermissions }) => {


  
  const CompanyAdminSurveys = () => (
    <NewAdminSurveys
      companyInfo={companyInfo}
      history={history}
      yearList={yearList}
      selectedYearInfo={selectedYearInfo}
      // selectedTabId={1}
      role={role}
      allowedRoles={getPermissionStatus("Create behavior library", userPermissions)}
    />
  );

  // const AdminSurveyInsights = () => (
  //   <SurveyInsights
  //     companyInfo={companyInfo}
  //     history={history}
  //     yearList={yearList}
  //     selectedYearInfo={selectedYearInfo}
  //     // selectedTabId={1}
  //     role={role}
  //     allowedRoles={getPermissionStatus("Create behavior library", userPermissions)}
  //   />
  // );

  





  return (
    <Switch>
      <Route path="/company/surveys" render={CompanyAdminSurveys} exact={true} />
      <Route path="/company/surveys/completed" render={CompanyAdminSurveys} exact={true} />
      <Route path="/company/surveys/library" render={CompanyAdminSurveys} exact={true} />
      {/* <Route path="/company/surveys/analytics/:id" render={AdminSurveyInsights} exact={true} />
      <Route path="/company/surveys/insight/:id" render={AdminSurveyInsights} exact={true} />
      <Route path="/company/surveys/feedback/:id" render={AdminSurveyInsights} exact={true} /> */}
    </Switch>
  );
};

AdminSurveys.propTypes = {
  companies: PropTypes.array,
  companyInfo: PropTypes.object,
  setCompany: PropTypes.func,
  role: PropTypes.string,
  history: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  userPermissions: PropTypes.array,
  getPlacesAction: PropTypes.func.isRequired,
  places: PropTypes.array,
  isLoading: PropTypes.bool,
  fetchOndemandCategory: PropTypes.func,
  fetchEventDetailsById:PropTypes.func,
  networkError: PropTypes.string,
  fetchCommentsOnEvent:PropTypes.func,
  postReplyOnComment:PropTypes.func,
  postCommentOnEvent:PropTypes.func,
  replySuccess: PropTypes.string,
  profileImage: PropTypes.string,
  attendEvent: PropTypes.func,
  getEventAttendees: PropTypes.func,
  createOndemandEvent: PropTypes.func,
};



export default connect(null, null)(AdminSurveys);
